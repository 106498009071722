"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
const toastr = window.toastr;
class default_1 extends stimulus_1.Controller {
    initialize() {
        super.initialize();
        window.addEventListener('close-modal', (event) => {
            if (!("detail" in event)
                ||
                    typeof (event.detail) !== "object"
                ||
                    event.detail === null) {
                console.warn('Invalid event', event);
                return;
            }
            if (!("modal" in event.detail) || typeof (event.detail.modal) !== "string") {
                console.warn('No modal given');
                return;
            }
            $(event.detail.modal).modal('hide');
        });
    }
}
exports.default = default_1;
