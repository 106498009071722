import controller_0 from '@symfony/ux-autocomplete/dist/controller.js';
import 'tom-select/dist/css/tom-select.bootstrap4.css';
import controller_1 from '@symfony/ux-live-component/dist/live_controller.js';
import '@symfony/ux-live-component/styles/live.css';
import controller_2 from '@symfony/ux-cropperjs/dist/controller.js';
import '@symfony/ux-cropperjs/dist/style.min.css';
import 'cropperjs/dist/cropper.min.css';
export default {
  'symfony--ux-autocomplete--autocomplete': controller_0,
  'live': controller_1,
  'symfony--ux-cropperjs--cropper': controller_2,
};