"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
const toastr = window.toastr;
class default_1 extends stimulus_1.Controller {
    initialize() {
        super.initialize();
        window.addEventListener('notify', (event) => {
            let method = toastr.success;
            if (!("detail" in event)
                ||
                    typeof (event.detail) !== "object"
                ||
                    event.detail === null) {
                console.warn('Invalid event', event);
                return;
            }
            if (!("message" in event.detail) || typeof (event.detail.message) !== "string") {
                console.warn('No message given');
                return;
            }
            const message = event.detail.message;
            let title = undefined;
            if ("title" in event.detail && typeof (event.detail.title) === "string") {
                title = event.detail.title;
            }
            if ("type" in event.detail && typeof (event.detail.type) === "string") {
                let type = event.detail.type;
                switch (type) {
                    case 'success':
                        method = toastr.success;
                        break;
                    case 'info':
                        method = toastr.info;
                        break;
                    case 'warning':
                        method = toastr.warning;
                        break;
                    case 'error':
                        method = toastr.error;
                        break;
                }
            }
            method(message, title);
        });
    }
}
exports.default = default_1;
