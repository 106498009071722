"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
const stimulus_use_1 = require("stimulus-use");
class default_1 extends stimulus_1.Controller {
    connect() {
        const parentElement = this.element.closest(this.parentSelectorValue);
        if (!parentElement) {
            throw new Error('Unable to connect group hover visibility controller because parent selector cannot be found');
        }
        (0, stimulus_use_1.useHover)(this, { element: parentElement });
    }
    mouseEnter() {
        this.element.classList.remove(...this.onLeaveValue);
        this.element.classList.add(...this.onEnterValue);
    }
    mouseLeave() {
        this.element.classList.remove(...this.onEnterValue);
        this.element.classList.add(...this.onLeaveValue);
    }
}
exports.default = default_1;
default_1.values = {
    parentSelector: String,
    onEnter: Array,
    onLeave: Array,
};
