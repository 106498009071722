"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class ImageEditorController extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        this.cropper = null;
        this.workaroundForEventBubblingOff = 'You have to set cropper element id. ' +
            'When this PR gets merged (https://github.com/symfony/ux/pull/602), there will no longer be a need to query for cropper element.';
    }
    connect() {
        let cropperElement = this.element.querySelector(this.cropselectorValue.toString());
        if (cropperElement !== null) {
            cropperElement.addEventListener('cropperjs:connect', (this._onConnect.bind(this)));
        }
        else {
            throw this.workaroundForEventBubblingOff;
        }
    }
    disconnect() {
        // You should always remove listeners when the controller is disconnected to avoid side effects
        let cropperElement = this.element.querySelector(this.cropselectorValue.toString());
        if (cropperElement !== null) {
            cropperElement.removeEventListener('cropperjs:connect', (this._onConnect.bind(this)));
        }
        else {
            console.warn(this.workaroundForEventBubblingOff);
        }
    }
    _onConnect(event) {
        this.cropper = event.detail.cropper;
        event.detail.img.addEventListener('ready', () => {
            $(`#imageEditorModal button-container :button`).map(function (index, navBtn) {
                navBtn.style.display = 'block';
            });
        });
    }
    rotateClockWise(event) {
        event.preventDefault();
        this._rotateAndSetInputData(this.degreeClockWiseValue);
    }
    rotateCounterClockWise(event) {
        event.preventDefault();
        this._rotateAndSetInputData(this.degreeCounterClockWiseValue);
    }
    _rotateAndSetInputData(degree) {
        if (this.cropper !== null) {
            this.cropper.rotate(degree.valueOf());
            let encoded = this.cropper.getCroppedCanvas({
                maxWidth: 4096, maxHeight: 4096
            }).toDataURL();
            if (this.hasInputOutlet) {
                this.inputOutlet.setValue(encoded);
            }
            else {
                console.error("Input outlet does not exist. You should have 'data-image-editor-input-outlet' defined in the html.");
            }
        }
        else {
            console.error("cropper uninitialized");
        }
    }
}
exports.default = ImageEditorController;
ImageEditorController.values = {
    cropselector: String,
    degreeClockWise: { type: Number, default: 90 },
    degreeCounterClockWise: { type: Number, default: -90 }
};
ImageEditorController.outlets = ["input"];
