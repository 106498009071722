export const ASSETS_MULTI_SELECT_MORE_ITEMS = {"id":"assets_multi_select_more_items","translations":{"frontend+intl-icu":{"en":"{ items, plural,\n  =0 { There are no more items }\n  =1 { There is one more item }\n  other { There are # more items }\n}"}}};
export const ASSETS_MULTI_SELECT_TEXTS_EMPTY = {"id":"assets_multi_select_texts_empty","translations":{"frontend+intl-icu":{"en":"empty"}}};
export const ASSETS_MULTI_SELECT_TEXTS_EXCEED = {"id":"assets_multi_select_texts_exceed","translations":{"frontend+intl-icu":{"en":"number of tags exceeded"}}};
export const ASSETS_MULTI_SELECT_TEXTS_PATTERN = {"id":"assets_multi_select_texts_pattern","translations":{"frontend+intl-icu":{"en":"pattern mismatch"}}};
export const ASSETS_MULTI_SELECT_TEXTS_DUPLICATE = {"id":"assets_multi_select_texts_duplicate","translations":{"frontend+intl-icu":{"en":"already exists"}}};
export const ASSETS_MULTI_SELECT_TEXTS_NOT_ALLOWED = {"id":"assets_multi_select_texts_notAllowed","translations":{"frontend+intl-icu":{"en":"not allowed"}}};
export const DURATION_HOURS = {"id":"duration_hours","translations":{"frontend+intl-icu":{"en":"{hours, plural,\n    one   {One hour}\n    other {# hours}\n}"}}};
export const DURATION_MINUTES = {"id":"duration_minutes","translations":{"frontend+intl-icu":{"en":"{minutes, plural,\n    one   {One minute}\n    other {# minutes}\n}"}}};
export const DURATION_SECONDS = {"id":"duration_seconds","translations":{"frontend+intl-icu":{"en":"{seconds, plural,\n    one   {One second}\n    other {# seconds}\n}"}}};
export const AND = {"id":"and","translations":{"frontend+intl-icu":{"en":"and"}}};
export const PLACEHOLDER = {"id":"placeholder","translations":{"frontend+intl-icu":{"en":"Select a value"}}};
export const LAB_INTEGRATION_SAMPLE_ORDERS_ACTIONS = {"id":"lab_integration.sample_orders.actions","translations":{"frontend+intl-icu":{"en":"Actions"}}};
export const LAB_INTEGRATION_SAMPLE_ORDERS_ARCHIVED = {"id":"lab_integration.sample_orders.archived","translations":{"frontend+intl-icu":{"en":"date removed"}}};
export const LAB_INTEGRATION_SAMPLE_ORDERS_CLIENT = {"id":"lab_integration.sample_orders.client","translations":{"frontend+intl-icu":{"en":"Client"}}};
export const LAB_INTEGRATION_SAMPLE_ORDERS_CREATION = {"id":"lab_integration.sample_orders.creation","translations":{"frontend+intl-icu":{"en":"Date created"}}};
export const LAB_INTEGRATION_SAMPLE_ORDERS_LAB = {"id":"lab_integration.sample_orders.lab","translations":{"frontend+intl-icu":{"en":"Lab"}}};
export const LAB_INTEGRATION_SAMPLE_ORDERS_ID = {"id":"lab_integration.sample_orders.id","translations":{"frontend+intl-icu":{"en":"Id"}}};
export const LAB_INTEGRATION_SAMPLE_ORDERS_LINK = {"id":"lab_integration.sample_orders.link","translations":{"frontend+intl-icu":{"en":"Link"}}};
export const LAB_INTEGRATION_SAMPLE_ORDERS_LOCATION = {"id":"lab_integration.sample_orders.location","translations":{"frontend+intl-icu":{"en":"Building"}}};
export const LAB_INTEGRATION_SAMPLE_ORDERS_PACKAGES = {"id":"lab_integration.sample_orders.packages","translations":{"frontend+intl-icu":{"en":"Packages"}}};
export const LAB_INTEGRATION_SAMPLE_ORDERS_PLANNED = {"id":"lab_integration.sample_orders.planned","translations":{"frontend+intl-icu":{"en":"Date scheduled"}}};
export const LAB_INTEGRATION_SAMPLE_ORDERS_REFERENCE = {"id":"lab_integration.sample_orders.reference","translations":{"frontend+intl-icu":{"en":"Reference"}}};
export const LAB_INTEGRATION_SAMPLE_ORDERS_SAMPLES = {"id":"lab_integration.sample_orders.samples","translations":{"frontend+intl-icu":{"en":"Number of samples"}}};
export const LAB_INTEGRATION_SAMPLE_ORDERS_STATUS = {"id":"lab_integration.sample_orders.status","translations":{"frontend+intl-icu":{"en":"Status"}}};
export const LAB_INTEGRATION_VIEW_EXTERNAL_REFERENCE_REFERENCE = {"id":"lab_integration.view.external_reference.reference","translations":{"frontend+intl-icu":{"en":"({reference})"}}};
export const LAB_INTEGRATION_VIEW_EXTERNAL_REFERENCE_TOOLTIP = {"id":"lab_integration.view.external_reference.tooltip","translations":{"frontend+intl-icu":{"en":"External lab ID"}}};
export const LAB_INTEGRATION_SAMPLE_ORDER_NO_TASKGROUP = {"id":"lab_integration.sample_order.no_taskgroup","translations":{"frontend+intl-icu":{"en":"Loose sample"}}};
export const LAB_INTEGRATION_SAMPLE_ORDER_TASK_GROUP_NO_RIGHTS = {"id":"lab_integration.sample_order.task_group_no_rights","translations":{"frontend+intl-icu":{"en":"No rights to view task group"}}};
export const LAB_INTEGRATION_SAMPLE_ORDER_VIEW_TASKGROUP = {"id":"lab_integration.sample_order.view_taskgroup","translations":{"frontend+intl-icu":{"en":"See taskgroup"}}};
