"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
require("../../modules/data-table");
const stimulus_use_1 = require("stimulus-use");
require("datatables.net-scroller");
class default_1 extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        this.table = undefined;
    }
    async connect() {
        (0, stimulus_use_1.useDebounce)(this, { wait: 700 });
        await this.initializeDatatables();
        window.addEventListener('resize', this.resize.bind(this));
    }
    disconnect() {
        window.removeEventListener('resize', this.resize.bind(this));
    }
    async initializeDatatables() {
        this.table = $(this.tableTarget).DataTable(this.configValue);
    }
    resize() {
        const table = this.table;
        if (!table) {
            return;
        }
        table.columns.adjust();
    }
}
exports.default = default_1;
default_1.values = {
    config: {
        type: Object,
        default: {}
    },
};
default_1.targets = ["table"];
default_1.debounces = [{ name: 'searchFromInput', wait: 700 }, { name: 'resize', wait: 150 }];
