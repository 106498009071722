"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    updateUrl(e) {
        var _a, _b;
        e.preventDefault();
        const elements = document.querySelectorAll(this.turboFrameTargetValue);
        for (const element of elements) {
            element.setAttribute('src', (_b = (_a = this.element.attributes.getNamedItem('href')) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '');
        }
    }
}
exports.default = default_1;
default_1.values = {
    turboFrameTarget: String,
};
