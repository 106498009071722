"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    focus(event) {
        event.preventDefault();
        this.element.focus();
    }
    setValue(encoded) {
        this.element.value = encoded;
    }
}
exports.default = default_1;
