"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
require("../../modules/data-table");
const stimulus_use_1 = require("stimulus-use");
require("datatables.net-scroller");
class default_1 extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        /**
         * Custom options that the server can process to alter, for example, the dataset that is used
         */
        this.customOptions = {};
        this.table = undefined;
    }
    async connect() {
        (0, stimulus_use_1.useDebounce)(this, { wait: 700 });
        await this.initializeDatatables();
        window.addEventListener('resize', this.resize.bind(this));
    }
    disconnect() {
        window.removeEventListener('resize', this.resize.bind(this));
    }
    async initializeDatatables() {
        var _a;
        try {
            // @ts-ignore
            this.table = await $(this.tableContainerTarget).initDataTables(this.configValue, { ...{ searching: true }, ...this.extraConfigValue });
            (_a = this.table) === null || _a === void 0 ? void 0 : _a.on('preXhr.dt', (event, settings, data) => {
                data.options = this.customOptions;
            });
            if ($('#dt.dataTable thead .trend_analysis_date_sort').length > 0) {
                $('#dt.dataTable thead .trend_analysis_date_sort').attr('data-action', "change->datatables#setCustomOption");
                $('#dt.dataTable thead .trend_analysis_date_sort').attr('data-datatables-option-name-param', "sort_state");
                $('#dt.dataTable thead .trend_analysis_date_sort').attr('data-datatables-option-value-param', "ASC");
            }
        }
        catch (throwable) {
            if (typeof this.tableErrorTarget !== 'undefined' && typeof this.tableErrorTarget.id !== 'undefined') {
                if ($("#" + this.tableErrorTarget.id).length > 0) {
                    $("#" + this.tableErrorTarget.id).html("Data can't be loaded");
                }
            }
        }
    }
    resize() {
        const table = this.table;
        if (!table) {
            return;
        }
        table.columns.adjust();
    }
    setCustomOption(event) {
        var _a;
        const paramCarryingProperty = event instanceof CustomEvent ? event.detail : event.params;
        const name = paramCarryingProperty['optionName'];
        const value = paramCarryingProperty['optionValue'];
        this.customOptions[name] = value;
        (_a = this.table) === null || _a === void 0 ? void 0 : _a.draw();
    }
    searchColumnFromInput(event) {
        var _a;
        const columnIndex = event.params['searchColumnIndex'];
        const inputElement = event.target;
        if (!(inputElement instanceof HTMLInputElement) && !(inputElement instanceof HTMLSelectElement)) {
            throw new Error("Unable to get a value to search with from an element that is not an HTMLInputElement");
        }
        const value = inputElement.value;
        (_a = this.table) === null || _a === void 0 ? void 0 : _a.column(columnIndex).search(value).draw();
    }
    searchFromInput(event) {
        var _a;
        const inputElement = event.target;
        if (!(inputElement instanceof HTMLInputElement)) {
            throw new Error("Unable to get a value to search with from an element that is not an HTMLInputElement");
        }
        const value = inputElement.value;
        (_a = this.table) === null || _a === void 0 ? void 0 : _a.search(value).draw();
    }
    reload({ detail: { onReloadComplete } }) {
        if (!this.table) {
            throw new Error('Attempting to reload datatable but cannot find instance of it');
        }
        if (onReloadComplete) {
            const table = this.table;
            this.table.one('draw', (e) => {
                onReloadComplete(table);
            });
        }
        this.table.rows().invalidate().draw('page');
    }
    withTable({ detail: { withTable } }) {
        if (!this.table) {
            throw new Error('Attempting to reload datatable but cannot find instance of it');
        }
        withTable(this.table);
    }
}
exports.default = default_1;
default_1.values = {
    config: {
        type: Object,
        default: {}
    },
    extraConfig: {
        type: Object,
        default: {},
    },
};
default_1.targets = ["tableContainer", "tableError"];
default_1.debounces = [{ name: 'searchFromInput', wait: 700 }, { name: 'resize', wait: 150 }];
