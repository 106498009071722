"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        this.localStorageKey = '';
    }
    connect() {
        super.connect();
        this.localStorageKey = this.element.dataset.localStorageKey || '';
        if (this.localStorageKey.length > 0) {
            const storedSelection = localStorage.getItem(this.localStorageKey);
            if (storedSelection) {
                for (let i = 0; i < this.element.options.length; i++) {
                    this.element.options[i].selected = storedSelection.indexOf(this.element.options[i].value) >= 0;
                }
                // @ts-ignore
                this.element.tomselect.sync();
            }
            this.element.addEventListener('change', (e) => {
                const selection = Array.prototype.slice.call(this.element.querySelectorAll('option:checked'), 0).map(function (v) {
                    return v.value;
                });
                localStorage.setItem(this.localStorageKey, JSON.stringify(selection));
            });
        }
        else {
            console.warn('No localStorageKey data attribute set for ' + this.element);
        }
    }
    disconnect() {
        super.disconnect();
    }
}
exports.default = default_1;
