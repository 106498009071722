import JSZip from "jszip";

global.JSZip = JSZip;

import 'datatables.net';
import 'datatables.net-bs4';
import 'datatables.net-buttons';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';

import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.css';

import 'datatables.net-fixedcolumns';
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.css';

import 'datatables.net-scroller';
import 'datatables.net-scroller-bs4';
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.css';

import 'datatables.net-fixedheader';
import 'datatables.net-fixedheader-bs4';
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.css';

import 'datatables.net-plugins/sorting/natural';
import 'datatables.net-plugins/sorting/intl';
import 'datatables.net-plugins/api/processing()';

import './style.scss';

const isoMap = {
    "nl": `//cdn.datatables.net/plug-ins/${$.fn.DataTable.version}/i18n/nl-NL.json`,
    "en": `//cdn.datatables.net/plug-ins/${$.fn.DataTable.version}/i18n/en-GB.json`,
    "es": `//cdn.datatables.net/plug-ins/${$.fn.DataTable.version}/i18n/es-ES.json`,
    "de": `//cdn.datatables.net/plug-ins/${$.fn.DataTable.version}/i18n/de-DE.json`
}

function getLocale() {
    const isoLocale = document.documentElement.lang;

    return isoMap[isoLocale.toLowerCase()] || isoMap[isoLocale.split('-')[0].toLowerCase()] || null;
}

$.extend(true, $.fn.dataTable.defaults, {
    "language": {
        "url": getLocale()
    },
    buttons   : [],
    dom       :
        "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
        "<'row'<'col-sm-12'<'table-responsive'tr>>>" +
        "<'row'<'col-sm-12 col-md-auto'i><'col-sm-12 col-md-auto ml-md-auto'B><'col-sm-12 col-md-auto'p>>"
});
$.extend(true, $.fn.dataTable.Buttons.defaults, {
    dom: {
        button   : {
            className: 'btn btn-outline-primary'
        },
        container: {
            className: 'dataTables_wrapper dt-buttons btn-group flex-wrap'
        }
    }
});

let monitoredTables = [];

function initializeTables() {
    for (const table of $.fn.dataTable.tables()) {
        const $table    = $(table);
        const dataTable = $table.DataTable();
        if (dataTable.context && dataTable.context.length) {
            const [context] = dataTable.context;
            if (context._fixedHeader && context._fixedHeader.s.enable) {
                $table.data('fixed_header', true);
                monitoredTables.push($table);
            }
        }
    }
}

function checkFixedHeader() {
    for (const $table of monitoredTables) {
        const tableWidth  = $table.width();
        const parentWidth = $table.parent().width();
        const table       = $table.DataTable();

        if (tableWidth > parentWidth) {
            table.fixedHeader.enable(false);
        } else {
            table.fixedHeader.enable(true);
        }
    }
}

$(function () {
    setTimeout(() => {
        initializeTables();
        checkFixedHeader();
    });
})

$(window).on('resize', function () {
    setTimeout(() => {
        checkFixedHeader()
    }, 0);
});

const computeEmptyCols = (e, dtSettings) => {
    if (e.namespace !== 'dt') {
        return;
    }

    const options = dtSettings.oInit.hideEmptyCols;

    if (Array.isArray(options) || options === true) {
        const config = $.isPlainObject(options) ? options : {},
              api    = new $.fn.dataTable.Api(dtSettings);

        let emptyCount = 0;

        api.columns().every(function () {
            // Check if were only hiding specific columns
            if (Array.isArray(options) && ($.inArray(this.index(), options) === -1 && $.inArray(api.column(this.index()).dataSrc(), options) === -1)) {
                api.column(this.index()).visible(true);
                return;
            }

            const data = this.data();

            for (const element of data.toArray()) {
                if (element === null || element.length === 0 || dtSettings.oInit.columns[this.index()].defaultContent === element) {
                    emptyCount++;
                }
            }


            // If the # of empty is the same as the length, then no values in col were found
            if (emptyCount === data.toArray().length) {
                api.column(this.index()).visible(false);
            } else {
                api.column(this.index()).visible(true);
            }

            emptyCount = 0;
        });
    }
};

// On DT Initialization
$(document).on('init.dt draw.dt', computeEmptyCols);

try {
    $.fn.dataTable.ext.order.intl(document.documentElement.lang);
} catch (e) {
    console.warn(e);
}
