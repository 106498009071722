"use strict";
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _default_1_instances, _default_1_initializeAjaxForm;
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        _default_1_instances.add(this);
        this.hasInitializedAjaxForm = false;
    }
    connect() {
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_initializeAjaxForm).call(this);
    }
}
exports.default = default_1;
_default_1_instances = new WeakSet(), _default_1_initializeAjaxForm = function _default_1_initializeAjaxForm() {
    if (this.hasInitializedAjaxForm) {
        return;
    }
    this.element.classList.add('disabled');
    $(this.element).on('ajaxForm.bound', () => {
        this.element.classList.remove('disabled');
    });
    // @ts-ignore
    $(this.element).ajaxForm(this.configValue);
    this.hasInitializedAjaxForm = true;
};
default_1.values = {
    config: {
        type: Object,
        default: {}
    }
};
