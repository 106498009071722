"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    initialize() {
        super.initialize();
        window.addEventListener('ux:reload', () => {
            location.reload();
        });
    }
}
exports.default = default_1;
